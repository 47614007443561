<template>
  <div class="waiBox">
    <!-- 头部 -->
    <div class="headBox clearfix">
      <div class="imgBox">PACKING LIST</div>
    </div>
    <!-- SHIP TO -->
    <div class="adreesDa">
      <h3>SHIP TO</h3>
      <div class="shipto">
        <div class="contBox">
          <p>{{ printInfo.printPackingVO && printInfo.printPackingVO.addressVOS &&
            printInfo.printPackingVO.addressVOS[0].address }}</p>
          <p>{{ printInfo.printPackingVO &&printInfo.printPackingVO.addressVOS &&
            printInfo.printPackingVO.addressVOS[0].companyName }}</p>
          <p>{{ printInfo.printPackingVO && printInfo.printPackingVO.addressVOS &&
            printInfo.printPackingVO.addressVOS[0].consigneeName }}</p>
          <p>{{ printInfo.printPackingVO && printInfo.printPackingVO.addressVOS &&
            printInfo.printPackingVO.addressVOS[0].consigneePhone }}</p>
        </div>
      </div>
      <div class="txtBox">
        <p>
          DATE:
          <i>{{printInfo && printInfo.printPackingVO && dateConversionFn(printInfo.printPackingVO.date) }}</i>
        </p>
        <p>
          PI NO:
          <em>{{ printInfo && printInfo.printPackingVO && printInfo.printPackingVO.piNO }}</em>
        </p>
        <p>
          CSR'S PO:
          <em>{{ printInfo && printInfo.printPackingVO && printInfo.printPackingVO.clientPO }}</em>
        </p>
      </div>
    </div>

    <!-- 表格 -->
    <div>
      <table id="tableExcel" width="700px" border="1" cellspacing="0" cellpadding="5px">
        <thead>
          <tr>
            <th style="width: 100px;">Item name</th>
            <th style="width: 180px;">Description</th>
            <th style="width: 100px;">SHIP TERMS</th>
            <th style="width: 100px;">SHIP DATE</th>
            <th>QTY/CTN</th>
            <th>CTNS</th>
            <th style="width: 88px;">TOTAL QTY</th>
          </tr>
        </thead>

        <tbody v-if="printInfo && printInfo.printPackingVO && printInfo.printPackingVO.orderProductVOS">
          <tr v-for="(item, i) in printInfo.printPackingVO.orderProductVOS" :key="i">
            <td>
              <p>{{ item.productEn }}</p>
            </td>
            <td>
              <p>{{ item.printingEffect }}</p>
            </td>
            <td>
              <p>{{  item.productExtend.shippingMethodEn }}</p>
            </td>
            <td>
              <p>{{ dateConversionFn(item.productExtend.customerDeliveryDate) }}</p>
            </td>
            <td>
              <input type="text" v-model="item.amount" oninput="value=value.replace(/[^\d]/g,'')"
                @blur="calaTotal('QTY')" />
            </td>
            <td>
              <input type="text" v-model="item.ctns" oninput="value=value.replace(/[^\d]/g,'')"
                @blur="calaTotal('CTNS')" />
            </td>
            <td>
              <input type="text" v-model="item.amountT" oninput="value=value.replace(/[^\d]/g,'')"
                @blur="calaTotal('TOTAL')" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>TOTAL</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <!-- <input
                type="text"
                v-model="QTY"
                oninput="value=value.replace(/[^\d]/g,'')"
                readonly
              /> -->
            </td>
            <td>
              <input type="text" v-model="CTNS" oninput="value=value.replace(/[^\d]/g,'')" readonly />
            </td>
            <td>
              <input type="text" v-model="TOTAL" oninput="value=value.replace(/[^\d]/g,'')" readonly />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="ftTxt">
      <p>Port of loading{{ printInfo.printPackingVO && printInfo.printPackingVO.portOfLoading }}</p>
      <p>Port of destination{{ printInfo.printPackingVO &&printInfo.printPackingVO.destination }}</p>
      <!-- <p>Ship terms :{{printInfo.printPackingVO && printInfo.printPackingVO.shipTerms }}</p>
      <p>Ship date: {{ printInfo.printPackingVO &&printInfo.printPackingVO.shipDate }}</p> -->
      <p>Tracking B/L No:{{ printInfo.printPackingVO && printInfo.printPackingVO.trackingNo }}</p>
    </div>
    <div class="ftName">
      <label for="">Sales&nbsp;&nbsp;</label>
      <input type="text" readonly :value="(printInfo.printPackingVO || {}).sales" />
    </div>
  </div>
</template>

<script>
  import { orderPrintOrder } from '@/api/order.js'
  import viewCost from '@/views/order/orderList/util'

  export default {
    props: ['offerSheetInfo'],
    data() {
      return {
        isViewCost: viewCost(),
        printInfo: {},
        QTY: 0,
        CTNS: 0,
        TOTAL: 0,
      }
    },
    created() {
      this.getPrintInfo()
    },
    methods: {
      // 获取打印信息
      getPrintInfo() {
        const params = {
          orderId: this.$route.query.orderId,
          printType: 2,
          checkFlag: this.isViewCost ? 1 : 0,
        }
        orderPrintOrder(params).then((res) => {
          if (res?.code === '000000') {
            this.printInfo = res.data
            if (this.printInfo?.printPackingVO?.orderProductVOS?.length) {
              let orderProductVOS = this.printInfo.printPackingVO.orderProductVOS

              this.printInfo.printPackingVO.orderProductVOS = orderProductVOS.map(
                (item) => {
                  return {
                    ...item,
                    ctns: 0,
                    amountT: item.amount,
                  }
                }
              )

              this.QTY = this.utils.amountTotal(orderProductVOS, 'amount')
              this.TOTAL = this.QTY
            }
          }

        })
      },

      // 计算总和
      calaTotal(T) {
        const _list = this.printInfo?.printPackingVO?.orderProductVOS || []

        switch (T) {
          case 'QTY':
            this.QTY = this.utils.amountTotal(_list, 'amount')
            break
          case 'CTNS':
            this.CTNS = this.utils.amountTotal(_list, 'ctns')
            break
          case 'TOTAL':
            this.TOTAL = this.utils.amountTotal(_list, 'amountT')
            break
        }
      },
       // 转为美国日期格式
      dateConversionFn(dateValue) {
        if(!dateValue) return '';
        const date = new Date(dateValue);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(date);
        return formattedDate
      },
    },
  }
</script>


<style lang="scss" scoped>
  * {
    background: white;
  }

  .waiBox {
    width: 700px;
    margin: 0 auto;

    table {
      input {
        border: 0;
        width: 90%;
        padding-left: 10px;
      }
    }
  }

  .imgBox {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .imgBox img:first-child {
    width: 290px;
    height: 45px;
  }

  .imgBox img:last-child {
    width: 234px;
    height: 32px;
    margin-left: 62px;
  }

  .txtBox {
    width: 217px;
    height: 74px;
    float: right;
    /* margin-left: 105px; */
    border: 1px solid #000000;
  }

  .clearfix:before,
  .clearfix:after {
    content: '';
    display: block;
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }

  .txtBox p {
    text-align: center;
    font-size: 15px;
    font-family: 'PingFang Bold';
  }

  .txtBox p em {
    color: red;
  }

  .pTL {
    font-size: 22px !important;
    font-family: 'PingFang Bold';
    line-height: 37px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  /* SHIP TO */
  .adreesDa {
    margin-bottom: 20px;
    overflow: hidden;
  }

  .shipto {
    /* margin-bottom: 20px; */
    float: left;
  }

  .contBox {
    width: 377px;
    height: 74px;
    border: 1px solid #000000;
    /*line-height: 70px;*/
  }

  .contBox p {
    font-size: 10px;
    font-family: 'PingFang Bold';
  }

  /* 表格 */
  #tableExcel {
    border-collapse: collapse;
  }

  #tableExcel tr td {
    height: 20px;
  }

  #tableExcel tbody tr td {
    font-size: 10px;
    text-align: center;
  }

  #tableExcel tfoot tr td {
    text-align: center;
    font-family: 'PingFang Bold';
  }

  .ftTxt {
    margin-top: 50px;
    font-size: 10px;
    font-family: 'PingFang Bold';
  }

  .ftTxt p input {
    border: none;
    font-family: 'PingFang Bold';
  }

  .ftName {
    text-align: right;
  }

  .ftName input {
    height: 20px;
    border: 0px;
    border-bottom: 1px solid #000000;
  }
</style>
